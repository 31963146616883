@font-face {
  font-family: GrapeFont;
  src: url(./grape_font/Gilroy-Light.ttf);
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: GrapeFont;
  src: url(./grape_font/Gilroy-ExtraBold.ttf);
  font-weight:bold;
  font-style:normal;
}

html {
  height:100%;
  background:none;
  margin:0;
  padding:0
}

body .realtime-body{
  background: #19141F;  /* fallback for old browsers */
  background: linear-gradient(200deg, #bbd2c5, #536976, #292e49);
  height:100%;
  min-height: 100vh;
  padding:0;
  background-attachment: fixed;
}

body .realtime-body .description-desktop {
  word-wrap: break-word; /* Wraps the URL text within the available space */
}

body .realtime-body .description-mobile {
  textOverflow: ellipsis; /* Truncates the URL text after a certain length and adds an ellipsis (...) */
  overflow: hidden; /* Hides any overflow beyond the specified text length */
}

body .frictionless-body{
  background: #111111;  /* fallback for old browsers */
  background: linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d);
  height:100%;
  min-height: 100vh;
  padding:0;
  background-attachment: fixed;
}

.BlinkingDot {
  display: inline-block;
  margin-left: 10px;
}

@keyframes blinking-dot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.panelibh-accordion .MuiExpansionPanel-root {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 17px !important; /* Add the !important flag to override the .panelibh-accordion class */
}

.frictionless-body {
  margin: 0;
  font-size-adjust: none;
  font-family: GrapeFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .app-body {
  background-color: #3b2f50; /* Fallback solid color */
  background: linear-gradient(70deg, #3b2f50, #111111, #923CB5, #ffffff);
  background-size: 200% 200%; /* Reduced from 400% */
  /*animation: subtleGlow 30s ease-in-out infinite alternate; /* Shorter duration */
  height: 100%;
  min-height: 100vh;
  padding: 0;
  background-attachment: fixed;
  position: relative;
  will-change: background-position; /* Keeps it lightweight */
  -webkit-backface-visibility: hidden;
}

/* Subtle glowing effect */
@keyframes subtleGlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.app-body {
  margin: 0;
  font-size-adjust: none;
  font-family: GrapeFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.profileAvatarIcon:hover {
  color:#fff!important;
}

.grape-profile-background{
  background: linear-gradient(to bottom, #36325d 0%, #251A3A 48.6%, #251A3A 90.62%, #251A3A 10%);
  border-radius: 17px;
  min-height: 225px;
  min-width:250px;
  overflow:hidden;
  padding-bottom:2em;
  position:relative;
}

.app-header{
  background: none;
  backdrop-filter: blur(12.5px);
}

.grape-store-splash{
}
.grape-store-info{
}

.grape-dialect{
  margin-right:5px;
}
.grape-dialect .dialect{
}
.grape-dialect .dialect .dt-flex .dt-relative.dt-bg-black{
  background:none!important;
}

.grape-store-background{
  background: linear-gradient(to right, #4597D6 0%, #8D41A7 48.6%, #36325D 90.62%, #251A3A 100%);
}

.grape-art-profile-img{
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: auto;
}

.grape-art-generic-placeholder-container{
  padding: 1em; 
  margin-bottom: 3em;
  width: 100%;
  background: #13151C;
  border-radius: 24px;
  overflow: hidden;
  text-overflow: ellipsis;

}

.grape-art-feed-outer-container{
  position: relative;
  margin:0;
  padding:0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.grape-art-feed-inner-container{
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}

.grape-art-feed-inner-img{
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.grape-art-feed-overlay{
  position:relative;
}

.grape-art-generic-dark-box{
  background: #13151C;
  min-width: 100%;
  min-height: 100%;
  padding: 1em;
  margin-top: 1em;
  border-radius: 24px; 
  z-index: 9999;
}

@keyframes avataranimation {
  0%   {color: white; }
  25%  {color: rgb(144, 202, 249); }
  50%  {color: white; }
  75%  {color: rgb(144, 202, 249); }
  100% {color: white; }
}

.buyNowButton {
  position: relative!important;
  background: linear-gradient(270deg, #00F0DD 0%, #DC1FFF 100%);
  border-radius: 10px!important;
  color: #fff!important;
  animation: 0.5s ease-in;
}

.buyNowButton:hover {
  animation-name: avataranimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

#wallet-menu .MuiPaper-root {
  border-radius: 24px!important;
}

#wallet-menu ul {
  background:none!important;
}#wallet-menu ul li:first-child {
}

.grape-wallet-button{
  color:white!important;
  line-height: 20px !important;
  max-height:40px;
  background:none!important;
  border:1px solid #fff!important;
  border-radius: 24px!important;
  font-weight:bold;
}

ul li .grape-wallet-button{
  border:none!important;
  border-radius: 0!important;
}

.grape-wallet-button img{
  max-height:25px;
}
.grape-wallet-button .MuiTouchRipple-root{
  background:none!important;
}

.wallet-adapter-button-trigger {
  line-height: 20px !important;
  background:none!important;
  border:1px solid #fff!important;
  border-radius: 24px!important;
}

.grape-wallet-provider .MuiPaper-root{
  border-radius: 24px!important;
}

.grape-wallet-provider {
  backdrop-filter: blur(5px)!important;
}

.grape-wallet-provider .MuiDialogTitle-root{
  background:black!important;
}

@keyframes avataranimation {
  0%   {background: linear-gradient(270deg, #00F0DD 0%, #DC1FFF 100%);}
  10%  {background: linear-gradient(306deg, #00F0DD 0%, #DC1FFF 100%);}
  20%  {background: linear-gradient(342deg, #00F0DD 0%, #DC1FFF 100%);}
  30%  {background: linear-gradient(18deg, #00F0DD 0%, #DC1FFF 100%);}
  40%  {background: linear-gradient(54deg, #00F0DD 0%, #DC1FFF 100%);}
  50%  {background: linear-gradient(90deg, #00F0DD 0%, #DC1FFF 100%);}
  60%  {background: linear-gradient(126deg, #00F0DD 0%, #DC1FFF 100%);}
  70%  {background: linear-gradient(162deg, #00F0DD 0%, #DC1FFF 100%);}
  80% {background: linear-gradient(192deg, #00F0DD 0%, #DC1FFF 100%);}
  90% {background: linear-gradient(234deg, #00F0DD 0%, #DC1FFF 100%);}
  100% {background: linear-gradient(270deg, #00F0DD 0%, #DC1FFF 100%);}
}

@media screen and (max-width: 550px) {
  .likeTab{
    display:none;
  }
  
  /* Overriding MUI Stylings for mobile screens */
  .wallet-adapter-button,
  .grape-wallet-button {
    line-height: 20px !important;
  }
  .wallet-adapter-button-start-icon{
    margin-right:0!important;
  }
  .wallet-adapter-solana-domain{
    display:none;
  }
  
  .header-logo {
    width: 120px !important;
  }
}