@font-face {
  font-family: GrapeFont;
  src: url("Gilroy-Light.f10faff8.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: GrapeFont;
  src: url("Gilroy-ExtraBold.4f52db0c.ttf");
  font-weight: bold;
  font-style: normal;
}

html {
  background: none;
  height: 100%;
  margin: 0;
  padding: 0;
}

body .realtime-body {
  background: linear-gradient(200deg, #bbd2c5, #536976, #292e49) fixed;
  height: 100%;
  min-height: 100vh;
  padding: 0;
}

body .realtime-body .description-desktop {
  word-wrap: break-word;
}

body .realtime-body .description-mobile {
  textOverflow: ellipsis;
  overflow: hidden;
}

body .frictionless-body {
  background: linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d) fixed;
  height: 100%;
  min-height: 100vh;
  padding: 0;
}

.BlinkingDot {
  margin-left: 10px;
  display: inline-block;
}

@keyframes blinking-dot {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.panelibh-accordion .MuiExpansionPanel-root {
  background: #00000040;
  border-radius: 17px !important;
}

.frictionless-body {
  font-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: GrapeFont, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body .app-body {
  will-change: background-position;
  -webkit-backface-visibility: hidden;
  background: linear-gradient(70deg, #3b2f50, #111, #923cb5, #fff) 0 0 / 200% 200% fixed;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  position: relative;
}

@keyframes subtleGlow {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.app-body {
  font-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: GrapeFont, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.profileAvatarIcon:hover {
  color: #fff !important;
}

.grape-profile-background {
  background: linear-gradient(#36325d 0%, #251a3a 48.6% 90.62%, #251a3a 10%);
  border-radius: 17px;
  min-width: 250px;
  min-height: 225px;
  padding-bottom: 2em;
  position: relative;
  overflow: hidden;
}

.app-header {
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  background: none;
}

.grape-dialect {
  margin-right: 5px;
}

.grape-dialect .dialect .dt-flex .dt-relative.dt-bg-black {
  background: none !important;
}

.grape-store-background {
  background: linear-gradient(to right, #4597d6 0%, #8d41a7 48.6%, #36325d 90.62%, #251a3a 100%);
}

.grape-art-profile-img {
  opacity: .1;
  width: 200%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.grape-art-generic-placeholder-container {
  text-overflow: ellipsis;
  background: #13151c;
  border-radius: 24px;
  width: 100%;
  margin-bottom: 3em;
  padding: 1em;
  overflow: hidden;
}

.grape-art-feed-outer-container {
  margin: 1em 0;
  padding: 0;
  position: relative;
}

.grape-art-feed-inner-container {
  border-radius: 24px;
  position: relative;
  overflow: hidden;
}

.grape-art-feed-inner-img {
  opacity: .2;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.grape-art-feed-overlay {
  position: relative;
}

.grape-art-generic-dark-box {
  z-index: 9999;
  background: #13151c;
  border-radius: 24px;
  min-width: 100%;
  min-height: 100%;
  margin-top: 1em;
  padding: 1em;
}

@keyframes avataranimation {
  0% {
    background: linear-gradient(270deg, #00f0dd 0%, #dc1fff 100%);
  }

  10% {
    background: linear-gradient(306deg, #00f0dd 0%, #dc1fff 100%);
  }

  20% {
    background: linear-gradient(342deg, #00f0dd 0%, #dc1fff 100%);
  }

  30% {
    background: linear-gradient(18deg, #00f0dd 0%, #dc1fff 100%);
  }

  40% {
    background: linear-gradient(54deg, #00f0dd 0%, #dc1fff 100%);
  }

  50% {
    background: linear-gradient(90deg, #00f0dd 0%, #dc1fff 100%);
  }

  60% {
    background: linear-gradient(126deg, #00f0dd 0%, #dc1fff 100%);
  }

  70% {
    background: linear-gradient(162deg, #00f0dd 0%, #dc1fff 100%);
  }

  80% {
    background: linear-gradient(192deg, #00f0dd 0%, #dc1fff 100%);
  }

  90% {
    background: linear-gradient(234deg, #00f0dd 0%, #dc1fff 100%);
  }

  100% {
    background: linear-gradient(270deg, #00f0dd 0%, #dc1fff 100%);
  }
}

.buyNowButton {
  background: linear-gradient(270deg, #00f0dd 0%, #dc1fff 100%);
  animation: none;
  color: #fff !important;
  border-radius: 10px !important;
  position: relative !important;
}

.buyNowButton:hover {
  animation-name: avataranimation;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

#wallet-menu .MuiPaper-root {
  border-radius: 24px !important;
}

#wallet-menu ul {
  background: none !important;
}

.grape-wallet-button {
  max-height: 40px;
  font-weight: bold;
  color: #fff !important;
  background: none !important;
  border: 1px solid #fff !important;
  border-radius: 24px !important;
  line-height: 20px !important;
}

ul li .grape-wallet-button {
  border: none !important;
  border-radius: 0 !important;
}

.grape-wallet-button img {
  max-height: 25px;
}

.grape-wallet-button .MuiTouchRipple-root {
  background: none !important;
}

.wallet-adapter-button-trigger {
  background: none !important;
  border: 1px solid #fff !important;
  border-radius: 24px !important;
  line-height: 20px !important;
}

.grape-wallet-provider .MuiPaper-root {
  border-radius: 24px !important;
}

.grape-wallet-provider {
  -webkit-backdrop-filter: blur(5px) !important;
  backdrop-filter: blur(5px) !important;
}

.grape-wallet-provider .MuiDialogTitle-root {
  background: #000 !important;
}

@media screen and (width <= 550px) {
  .likeTab {
    display: none;
  }

  .wallet-adapter-button, .grape-wallet-button {
    line-height: 20px !important;
  }

  .wallet-adapter-button-start-icon {
    margin-right: 0 !important;
  }

  .wallet-adapter-solana-domain {
    display: none;
  }

  .header-logo {
    width: 120px !important;
  }
}

/*# sourceMappingURL=index.8c5cd766.css.map */
